import React, { useReducer } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { CheckoutHeader } from './CheckoutHeader';
import InternetPackage from '../utils/InternetPackage';
import { StepWrapper } from './StepWrapper';
import TvPackage from '../utils/TvPackage';
import styled from 'styled-components';

const reducer = (state, action) => {
	switch (action.type) {
		case 'update_package': {
			return {
				...state,
				packageInfo: action.selectedPackage
			};
		}
		case 'field': {
			let newPackage = state.packageInfo;
			newPackage[action.objField] = action.value === '' ? 1 : action.value;
			return {
				...state,
				[action.field]: action.value,
				packageInfo: newPackage
			};
		}
		case 'add_extra': {
			let newPackage = state.packageInfo;
			newPackage.setExtraAdded(action.index, action.isAdded);

			return {
				...state,
				packageInfo: newPackage
			};
		}
		case 'form_field': {
			return {
				...state,
				[action.field]: action.value
			};
		}
		case 'next_step': {
			window.scrollTo({ top: 0, behavior: 'smooth' });
			let nextStep = state.currentStep + 1;
			if (state.currentStep === 0 && state.packageInfo.isOnlyInternet) {
				nextStep += 1;
			}

			return {
				...state,
				currentStep: nextStep
			};
		}
		case 'valid_fields': {
			return {
				...state,
				firstNameIsValid: action.firstNameIsValid,
				secondNameIsValid: action.secondNameIsValid,
				emailIsValid: action.emailIsValid,
				telephoneIsValid: action.telephoneIsValid,
				cityIsValid: action.cityIsValid,
				addressIsValid: action.addressIsValid,
				selectedDateIsValid: action.selectedDateIsValid
			};
		}
		default: {
			return state;
		}
	}
};

let initialState = {
	tvCount: '',
	currentStep: 0,
	orderNumber:
		Date.now()
			.toString()
			.slice(0, 6) + makeID(3),
	firstName: '',
	secondName: '',
	address: '',
	selectedDate: null,
	email: '',
	telephone: '',
	city: '',
	agreeCheckbox: false,

	firstNameIsValid: true,
	secondNameIsValid: true,
	addressIsValid: true,
	selectedDateIsValid: true,
	emailIsValid: true,
	telephoneIsValid: true,
	cityIsValid: true
};

const Wrapper = styled.div`
	border-radius: 16px;
	margin-bottom: 5rem;
	box-shadow: 0px 0px 38px rgba(17, 6, 29, 0.2);
`;

const Checkout = ({ selectedPackage, packageSize, packageTvCount }) => {
	const data = useStaticQuery(graphql`
		query Checkout {
			bg: file(relativePath: { eq: "checkout/checkout-bg.png" }) {
				childImageSharp {
					fluid(maxWidth: 4200) {
						src
					}
				}
			}
			finalBg: file(relativePath: { eq: "checkout/checkout-final-step-bg.png" }) {
				childImageSharp {
					fluid(maxWidth: 4200) {
						src
					}
				}
			}
		}
	`);

	initialState.packageInfo =
		selectedPackage.package === 'Само интернет'
			? new InternetPackage(
					selectedPackage.id,
					packageSize,
					selectedPackage.speed,
					selectedPackage.price,
					{ ...selectedPackage }
			  )
			: new TvPackage(
					selectedPackage.id,
					packageSize,
					selectedPackage.speed,
					selectedPackage.price,
					{ ...selectedPackage },
					selectedPackage.package,
					'',
					packageTvCount
			  );

	const [state, dispatch] = useReducer(reducer, initialState);
	const { currentStep } = state;

	return (
		<>
			<div
				className="row w-100 d-flex justify-content-center checkout"
				style={{ paddingTop: '16rem' }}
			>
				<div className="col-12 col-md-11 col-xl-10">
					<Wrapper>
						<CheckoutHeader linkBackTo={'/za-doma/'} step={currentStep} />
						<StepWrapper
							bg={
								currentStep === 3
									? data.finalBg.childImageSharp.fluid.src
									: data.bg.childImageSharp.fluid.src
							}
							title={'Персонализирай своя NetSurf пакет'}
							dispatch={dispatch}
							parentState={state}
						/>
					</Wrapper>
				</div>
			</div>
		</>
	);
};

export default Checkout;

function makeID(length) {
	var result = '';
	var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

