import React from 'react';
import styled from 'styled-components';
import { Link, useStaticQuery, graphql } from 'gatsby';

export const CheckoutHeader = ({ linkBackTo, step }) => {
  const Header = styled.div`
    background-color: #151F41;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    padding: 1.5rem 4rem 1.5rem 4rem;
  `;
  const data = useStaticQuery(graphql`
    query CheckoutHeader {
      step1: file(relativePath: {eq: "checkout/cstep-1.png"}) {
        childImageSharp {
          fluid(maxWidth: 700) {
            src
          }
        }
      }
      step2: file(relativePath: {eq: "checkout/cstep-2.png"}) {
        childImageSharp {
          fluid(maxWidth: 700) {
            src
          }
        }
      }
      step3: file(relativePath: {eq: "checkout/cstep-3.png"}) {
        childImageSharp {
          fluid(maxWidth: 700) {
            src
          }
        }
      }
      step4: file(relativePath: {eq: "checkout/cstep-4.png"}) {
        childImageSharp {
          fluid(maxWidth: 700) {
            src
          }
        }
      }
    }
  `);
  const stepImages = [data.step1.childImageSharp.fluid.src, data.step2.childImageSharp.fluid.src, data.step3.childImageSharp.fluid.src, data.step4.childImageSharp.fluid.src];
  return (<>
    <Header className='row w-100'>
      <div className='col-lg-3 d-none d-lg-flex justify-content-start align-items-center'>
        <Link to={linkBackTo} className='link text-white'><span className='icon back-blue-arrow mr-1'></span> Назад</Link>
      </div>
      <div className='col-12 col-lg-6 d-flex justify-content-center align-items-center'>
        <img src={stepImages[step]} className='img-fluid' alt='Стъпки' />
      </div>
      <div className='col-xl-3 d-none d-xl-flex justify-content-end align-items-center'>
        <div className='d-inline-block text-right'>
          <h2 className='text-white m-0'><span className='icon green-phone'></span><a className='text-white ml-1' href={"tel:088 299 1611"}>088 299 1611</a></h2>
        </div>
      </div>
      <div>
      </div>
    </Header>
  </>);
};
